import { Navigation, Pagination, SwiperOptions } from 'swiper';

export const QUARTER_MONTH_TREE = [
    { id: 13, label: 'Q1', parent: 0, extra: {}, isSelected: false },
    { id: 14, label: 'Q2', parent: 0, extra: {}, isSelected: false },
    { id: 15, label: 'Q3', parent: 0, extra: {}, isSelected: false },
    { id: 16, label: 'Q4', parent: 0, extra: {}, isSelected: false },
    { id: 1, label: 'Jan', parent: 13, isSelected: false },
    { id: 2, label: 'Feb', parent: 13, isSelected: false },
    { id: 3, label: 'Mar', parent: 13, isSelected: false },
    { id: 4, label: 'Apr', parent: 14, isSelected: false },
    { id: 5, label: 'May', parent: 14, isSelected: false },
    { id: 6, label: 'Jun', parent: 14, isSelected: false },
    { id: 7, label: 'Jul', parent: 15, isSelected: false },
    { id: 8, label: 'Aug', parent: 15, isSelected: false },
    { id: 9, label: 'Sep', parent: 15, isSelected: false },
    { id: 10, label: 'Oct', parent: 16, isSelected: false },
    { id: 11, label: 'Nov', parent: 16, isSelected: false },
    { id: 12, label: 'Dec', parent: 16, isSelected: false }
];

export interface SELECTED_MONTH {
    id: number;
    label: string;
    parent: number;
    isSelected: boolean;
};

export const MAT_QUARTER_MONTH_TREE = [
    {
        item: 'Q1',
        children: [{ item: 'Jan' }, { item: 'Feb' }, { item: 'Mar' }]
    },
    {
        item: 'Q2',
        children: [{ item: 'Apr' }, { item: 'May' }, { item: 'Jun' }]
    },
    {
        item: 'Q3',
        children: [{ item: 'Jul' }, { item: 'Aug' }, { item: 'Sep' }]
    },
    {
        item: 'Q4',
        children: [{ item: 'Oct' }, { item: 'Nov' }, { item: 'Dec' }]
    }
];

export const MTH_LIST = [
    { id: 1, label: 'Jan', qid: 1 },
    { id: 2, label: 'Feb', qid: 1 },
    { id: 3, label: 'Mar', qid: 1 },
    { id: 4, label: 'Apr', qid: 2 },
    { id: 5, label: 'May', qid: 2 },
    { id: 6, label: 'Jun', qid: 2 },
    { id: 7, label: 'Jul', qid: 3 },
    { id: 8, label: 'Aug', qid: 3 },
    { id: 9, label: 'Sep', qid: 3 },
    { id: 10, label: 'Oct', qid: 4 },
    { id: 11, label: 'Nov', qid: 4 },
    { id: 12, label: 'Dec', qid: 4 }
];

export const SOB_TREE = [
    { id: 1, label: 'Hotel Direct', parent: 0, extra: {} },
    { id: 2, label: 'Brand Web', parent: 0, extra: {} },
    { id: 3, label: 'Contact Centre', parent: 0, extra: {} },
    { id: 4, label: 'Indirect Online', parent: 0, extra: {} },
    { id: 5, label: 'GDS', parent: 0, extra: {} },
    { id: 11, label: 'Email', parent: 1 },
    { id: 12, label: 'Fax', parent: 1 },
    { id: 13, label: 'Other', parent: 1 },
    { id: 14, label: 'Telephone', parent: 1 },
    { id: 15, label: 'Walk In', parent: 1 },
    { id: 21, label: 'Affiliates', parent: 2 },
    { id: 22, label: 'Brand Web Acquisition', parent: 2 },
    { id: 23, label: 'Brand Web Direct', parent: 2 },
    { id: 24, label: 'Brand Web Other', parent: 2 },
    { id: 25, label: 'CRM - Email', parent: 2 },
    { id: 26, label: 'Meta Search', parent: 2 },
    { id: 27, label: 'Mobile & Apps', parent: 2 },
    { id: 28, label: 'Radisson Rewards', parent: 2 },
    { id: 29, label: 'Search Contribution', parent: 2 },
    { id: 31, label: 'Contact Centre Asia', parent: 3 },
    { id: 32, label: 'Contact Centre Americas', parent: 3 },
    { id: 33, label: 'Contact Centre Europe', parent: 3 },
    { id: 34, label: 'Contact Centre Other', parent: 3 },
    { id: 41, label: 'Booking', parent: 4 },
    { id: 42, label: 'Expedia', parent: 4 },
    { id: 43, label: 'Ctrip', parent: 4 },
    { id: 44, label: 'MakeMyTrip', parent: 4 },
    { id: 45, label: 'Other OTA', parent: 4 },
    { id: 51, label: 'Amadeus', parent: 5 },
    { id: 52, label: 'Apollo', parent: 5 },
    { id: 53, label: 'Sabre', parent: 5 },
    { id: 54, label: 'Worldspan', parent: 5 }
];
export const SOB_TREE_LHG = [
    { id: 1, label: 'Direct Hotel', parent: 0, extra: {} },
    { id: 4, label: 'Call Centers', parent: 0, extra: {} },
    { id: 3, label: 'Groups Reservation', parent: 0, extra: {} },
    { id: 2, label: 'Direct Web', parent: 0, extra: {} },
    { id: 5, label: 'Indirect Web', parent: 0, extra: {} },
    { id: 6, label: 'GDS', parent: 0, extra: {} },
    { id: 7, label: 'Other', parent: 0, extra: {} },
    { id: 11, label: 'Kiosk', parent: 1 },
    { id: 12, label: 'Direct Hotel', parent: 1 },
    { id: 13, label: 'Hotel Transfer', parent: 1 },
    { id: 14, label: 'Group Desk', parent: 1 },
    { id: 21, label: 'Brand', parent: 2 },
    { id: 22, label: 'Metasearch', parent: 2 },
    { id: 23, label: 'Prefered Web Partners', parent: 2 },
    { id: 32, label: 'Web ProAccess', parent: 3 },
    { id: 41, label: 'Call Centers', parent: 4 },
    { id: 45, label: 'Other OTA', parent: 5 },
    { id: 52, label: 'Booking', parent: 5 },
    { id: 53, label: 'Expedia', parent: 5 },
    { id: 55, label: 'Plateforme Corporate', parent: 5 },
    { id: 56, label: 'Plateforme Wholesaler', parent: 5 },
    { id: 59, label: 'Other OTA', parent: 5 },
    { id: 61, label: 'Amadeus', parent: 6 },
    { id: 62, label: 'Sabre', parent: 6 },
    { id: 63, label: 'Galileo', parent: 6 },
    { id: 64, label: 'Worldspan', parent: 6 },
    { id: 65, label: 'Other GDS', parent: 6 },
    { id: 71, label: 'Other', parent: 7 }
];

export const MKT_TREE = [
    { id: 1, label: 'RAC', parent: 5 },
    { id: 2, label: 'TOF', parent: 5 },
    { id: 3, label: 'QOF', parent: 5 },
    { id: 4, label: 'BAO', parent: 5 },
    { id: 21, label: 'RER', parent: 5 },
    { id: 5, label: 'B2C', parent: 0, extra: {} },
    { id: 6, label: 'BIQ', parent: 9 },
    { id: 7, label: 'BIT', parent: 9 },
    { id: 8, label: 'CBI', parent: 9 },
    { id: 9, label: 'COR', parent: 0, extra: {} },
    { id: 10, label: 'BGO', parent: 0 },
    { id: 11, label: 'BGR', parent: 0 },
    { id: 12, label: 'FIT', parent: 0 },
    { id: 13, label: 'LGR', parent: 15 },
    { id: 14, label: 'LGS', parent: 15 },
    { id: 15, label: 'LG', parent: 0, extra: {} },
    { id: 16, label: 'CRW', parent: 18 },
    { id: 17, label: 'LYO', parent: 18 },
    { id: 18, label: 'CREW', parent: 0, extra: {} },
    { id: 20, label: 'OTH', parent: 23 },
    { id: 22, label: 'NRG', parent: 23 },
    { id: 23, label: 'OTHER', parent: 0, extra: {} },
    { id: 24, label: 'CROP', parent: 100 },
    { id: 25, label: 'LEI', parent: 100 },
    { id: 26, label: 'TRANSIENT', parent: 100 },
    { id: 27, label: 'CONFIDENTIAL', parent: 100 },
    { id: 28, label: 'ONLINE', parent: 100 },
    { id: 29, label: 'CREW', parent: 100 },
    { id: 30, label: 'GROUP', parent: 100 },
    { id: 31, label: 'OTHER', parent: 100 }
];
// v.1.0.0
/*
export const MKT_TREE_APAC = [
    { id: 1, label: 'CON', parent: 6 },
    { id: 2, label: 'DAY', parent: 6 },
    { id: 3, label: 'NOL', parent: 6 },
    { id: 4, label: 'NOO', parent: 6 },
    { id: 5, label: 'PRO', parent: 6 },
    { id: 6, label: 'TRANSIENT', parent: 0, extra: {} },
    { id: 7, label: 'GRA', parent: 11 },
    { id: 8, label: 'GRC', parent: 11 },
    { id: 9, label: 'GRR', parent: 11 },
    { id: 10, label: 'GRT', parent: 11 },
    { id: 11, label: 'GROUP', parent: 0, extra: {} },
    { id: 12, label: 'COC', parent: 16 },
    { id: 13, label: 'COG', parent: 16 },
    { id: 14, label: 'COR', parent: 16 },
    { id: 15, label: 'LEI', parent: 16 },
    { id: 16, label: 'CONTRACT', parent: 0, extra: {} },
    { id: 17, label: 'OTH', parent: 18 },
    { id: 18, label: 'OTHER', parent: 0, extra: {} }
];
*/
// v.1.0.1
export const MKT_TREE_APAC = [
    { id: 1, label: 'COR', parent: 2 },
    { id: 2, label: 'CORP', parent: 0, extra: {} },
    { id: 3, label: 'LEI', parent: 4 },
    { id: 4, label: 'LEI', parent: 0, extra: {} },
    { id: 5, label: 'COC', parent: 10 },
    { id: 6, label: 'COG', parent: 10 },
    { id: 7, label: 'DAY', parent: 10 },
    { id: 8, label: 'NOL', parent: 10 },
    { id: 9, label: 'PRO', parent: 10 },
    { id: 10, label: 'TRANSIENT', parent: 0, extra: {} },
    { id: 11, label: 'CON', parent: 12 },
    { id: 12, label: 'CONFIDENTIAL', parent: 0, extra: {} },
    { id: 13, label: 'NOO', parent: 14 },
    { id: 14, label: 'ONLINE', parent: 0, extra: {} },
    { id: 15, label: 'GRC', parent: 16 },
    { id: 16, label: 'CREW', parent: 0, extra: {} },
    { id: 17, label: 'GRA', parent: 22 },
    { id: 18, label: 'GRI', parent: 22 },
    { id: 19, label: 'GRR', parent: 22 },
    { id: 20, label: 'GRS', parent: 22 },
    { id: 21, label: 'GRT', parent: 22 },
    { id: 22, label: 'GROUP', parent: 0, extra: {} },
    { id: 23, label: 'OTH', parent: 24 },
    { id: 24, label: 'OTHER', parent: 0, extra: {} }
];
export const MKT_TREE_LHG = [
    { id: 1, label: 'PFL', parent: 6, mkt_group: 'B2C' },
    { id: 2, label: 'PSFL', parent: 6, mkt_group: 'B2C' },
    { id: 3, label: 'PPP', parent: 6, mkt_group: 'B2C' },
    { id: 4, label: 'PBO', parent: 6, mkt_group: 'B2C' },
    { id: 5, label: 'PPO', parent: 6, mkt_group: 'B2C' },
    { id: 6, label: 'PUB', parent: 0, extra: {}, mkt_group: 'B2C' },
    { id: 7, label: 'IBC', parent: 8, mkt_group: 'B2C' },
    { id: 8, label: 'IBC', parent: 0, extra: {}, mkt_group: 'B2C' },
    { id: 9, label: 'ILC', parent: 10, mkt_group: 'B2B' },
    { id: 10, label: 'ILC', parent: 0, extra: {}, mkt_group: 'B2B' },
    { id: 11, label: 'RPAR', parent: 13, mkt_group: 'B2B' },
    { id: 12, label: 'REMP', parent: 13, mkt_group: 'B2B' },
    { id: 13, label: 'RPF', parent: 0, extra: {}, mkt_group: 'B2B' },
    { id: 14, label: 'GBU', parent: 15, mkt_group: 'B2B' },
    { id: 15, label: 'GBU', parent: 0, extra: {}, mkt_group: 'B2B' },
    { id: 16, label: 'GLE', parent: 17, mkt_group: 'B2B' },
    { id: 17, label: 'GLE', parent: 0, extra: {}, mkt_group: 'B2B' },
    { id: 18, label: 'ONR', parent: 20, mkt_group: 'B2C' },
    { id: 19, label: 'OUN', parent: 20, mkt_group: 'B2C' },
    { id: 20, label: 'OTH', parent: 0, extra: {}, mkt_group: 'B2C' }
];
export const OPS_CURRENCY_LIST = [
    { id: 0, label: 'EUR' },
    { id: 1, label: 'EUR - BUD-rate (FXN)' }
];
export const CURRENCY_LIST = [
    'EUR',
    'AED',
    'ALL',
    'AMD',
    'ANG',
    'AOA',
    'ARS',
    'AUD',
    'AWG',
    'AZN',
    'BAM',
    'BBD',
    'BDT',
    'BGN',
    'BHD',
    'BND',
    'BOB',
    'BRL',
    'BYN',
    'BYR',
    'BZD',
    'CAD',
    'CDF',
    'CHF',
    'CLP',
    'CNY',
    'COP',
    'CRC',
    'CVE',
    'CZK',
    'DKK',
    'DZD',
    'EGP',
    'ETB',
    'FJD',
    'GBP',
    'GEL',
    'GHS',
    'GNF',
    'GTQ',
    'HKD',
    'HRK',
    'HUF',
    'IDR',
    'ILS',
    'INR',
    'IQD',
    'IRR',
    'ISK',
    'JMD',
    'JOD',
    'JPY',
    'KES',
    'KRW',
    'KWD',
    'KYD',
    'KZT',
    'LBP',
    'LKR',
    'LRD',
    'LYD',
    'MAD',
    'MDL',
    'MGA',
    'MKD',
    'MMK',
    'MNT',
    'MOP',
    'MUR',
    'MVR',
    'MXN',
    'MYR',
    'MZN',
    'NGN',
    'NOK',
    'NPR',
    'NZD',
    'OMR',
    'PEN',
    'PHP',
    'PKR',
    'PLN',
    'PYG',
    'QAR',
    'RON',
    'RSD',
    'RUB',
    'RWF',
    'SAR',
    'SCR',
    'SDG',
    'SEK',
    'SGD',
    'SLL',
    'SLE',
    'SSP',
    'SYP',
    'THB',
    'TND',
    'TRY',
    'TTD',
    'TWD',
    'TZS',
    'UAH',
    'UGX',
    'USD',
    'UYU',
    'UZS',
    'VES',
    'VND',
    'XAF',
    'XOF',
    'XPF',
    'YER',
    'ZAR',
    'ZMW',
    'ZWL'
].map((currency, idx) => {
    return { id: idx, label: currency };
});

/**
 * Required to check which rows are expandables/hidden at DataTables with this feature
 */
export interface NodeRows {
    visible: boolean;
    expandableRow: number;
    hiddeableRows: number[];
    map?: Map<any, NodeRows>;
    expandableRows?: number[];
}
/**
 * Required by PdfMakeService to print the content of the sections
 */
export interface SectionPrintData {
    content: any[];
    styles?: any;
}
/**
 * Required by WkHtmlToPdf to pdf the content of the sections
 */
export interface SectionHtmlData {
    html: string;
}
export const DEFAULT_SWIPER_CONFIG: SwiperOptions = {
    modules: [Navigation, Pagination],
    direction: 'horizontal',
    slidesPerView: 'auto',
    keyboard: true,
    pagination: { el: '.swiper-pagination', type: 'fraction' },
    navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }
};
/**
 * Comparables official order
 */
//export const comparables: string[] = ['Comparable', 'Reno 2018', 'Reno 2019', 'Reno 2020','Reno 2021', 'Outliers', 'Open 2018', 'Open 2019', 'Open 2020' , 'Open 2021', 'Exit'];
/**
 * Perimeter Group official order
 */
export const perimeterGrp: string[] = [
    'LFL',
    'Ramp up',
    'Displacement',
    'Opening',
    'Exit',
    'N/A',
    'Top Adjustment'
];
export const dowNames: string[] = [null, 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const dowNamesUS = [null, 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const dowNamesFull: string[] = [
    null,
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
];
export const dowUpperNames: string[] = [
    null,
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
    'SUN'
];
export const channelNames: string[] = [
    null,
    'Hotel Direct',
    'Brand Web',
    'Contact Centre',
    'Booking.com',
    'Expedia',
    'Other OTA',
    'GDS'
];
export const channelNamesLHG: string[] = [
    null,
    'Call Centers',
    'Direct Hotel',
    'Direct Web',
    'GDS',
    'Groups Reservation',
    'Hotel Direct',
    'Indirect Web',
    'Other',
];
export const LAST_FCST_PERIOD = 202008;
export const currencies = [
    'EUR',
    'AED',
    'AMD',
    'AZN',
    'BGN',
    'BHD',
    'BYR',
    'CHF',
    'CNY',
    'CVE',
    'CZK',
    'DKK',
    'DZD',
    'EGP',
    'ETB',
    'GBP',
    'GEL',
    'GHS',
    'HRK',
    'HUF',
    'ISK',
    'JOD',
    'KES',
    'KWD',
    'KZT',
    'LBP',
    'LYD',
    'MAD',
    'MDL',
    'MUR',
    'MZN',
    'NGN',
    'NOK',
    'OMR',
    'PLN',
    'QAR',
    'RON',
    'RSD',
    'RUB',
    'RWF',
    'SAR',
    'SEK',
    'SLL',
    'SLE',
    'TND',
    'TRY',
    'UAH',
    'UGX',
    'USD',
    'UZS',
    'XAF',
    'XOF',
    'ZAR',
    'ZMW'
];
export const colorsScale10: string[] = [
    '#eff2f3',
    '#e0e5e8',
    '#d1d8dc',
    '#c2cbd1',
    '#b3bec5',
    '#a3b1ba',
    '#94a4ae',
    '#8597a3',
    '#768a97',
    '#677d8c'
];
export const rgbArray = [
    [103, 125, 140], // Blue (#677D8C)
    [190, 200, 215], // Lightblue (#BEC8D7)
    [125, 125, 125], // Gray (#7D7D7D)
    [191, 144, 0], // Gold (#BF9000)
    [133, 151, 164], // Blue (#8597A4)
    [200, 210, 225], // Lightblue (#C8D2E1)
    [150, 150, 150], // Gray (#969696)
    [207, 164, 34], // Gold (#CFA422)
    [163, 178, 188], // Blue (#A3B2BC)
    [210, 220, 235], // Lightblue (#D2DCEB)
    [175, 175, 175], // Gray (#AFAFAF)
    [223, 185, 69], // Gold (#DFB945)
    [193, 205, 212], // Blue (#C1CDD4)
    [220, 230, 245], // Lightblue (#DCE6F5)
    [200, 200, 200], // Gray (#C8C8C8)
    [239, 205, 103], // Gold (#EFCD67)
    [223, 232, 237], // Blue (#E0E8ED)
    [230, 240, 255], // Lightblue (#E6F0FF)
    [225, 225, 225], // Gray (#E1E1E1)
    [255, 226, 138], // Gold (#FFE28A)
    [240, 246, 250], // Blue (#E0E8ED)
    [242, 246, 252], // Lightblue (#E6F0FF)
    [242, 242, 242], // Gray (#E1E1E1)
    [252, 229, 157] // Gold (#FFE28A)
];
export const rgbArrayDonuts = [
    [78, 101, 116],
    [144, 182, 238],
    [74, 74, 74],
    [189, 142, 0],
    [54, 136, 191],
    [117, 145, 163],
    [215, 229, 249],
    [128, 128, 128],
    [255, 191, 0],
    [37, 94, 131]
];

export const opsAreaMapping = [
    { country: 'Albania', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Andorra', old_ops_area: 'UKIRWE', new_ops_area: 'CESEE' },
    { country: 'Armenia', old_ops_area: 'EERUT', new_ops_area: 'CESEE' },
    { country: 'Austria', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Azerbaijan', old_ops_area: 'EERUT', new_ops_area: 'CESEE' },
    { country: 'Belarus', old_ops_area: 'EERUT', new_ops_area: 'NOWE' },
    { country: 'Belgium', old_ops_area: 'UKIRWE', new_ops_area: 'NOWE' },
    { country: 'Bulgaria', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Croatia', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Cyprus', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Czech Republic', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Denmark', old_ops_area: 'NOB', new_ops_area: 'NOWE' },
    { country: 'Estonia', old_ops_area: 'NOB', new_ops_area: 'CESEE' },
    { country: 'Finland', old_ops_area: 'NOB', new_ops_area: 'NOWE' },
    { country: 'France', old_ops_area: 'UKIRWE', new_ops_area: 'NOWE' },
    { country: 'Georgia', old_ops_area: 'EERUT', new_ops_area: 'CESEE' },
    { country: 'Germany', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Greece', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Hungary', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Iceland', old_ops_area: 'NOB', new_ops_area: 'NOWE' },
    { country: 'Ireland', old_ops_area: 'UKIRWE', new_ops_area: 'NOWE' },
    { country: 'Italy', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Jersey', old_ops_area: 'UKIRWE', new_ops_area: 'NOWE' },
    { country: 'Kazakhstan', old_ops_area: 'EERUT', new_ops_area: 'CESEE' },
    { country: 'Latvia', old_ops_area: 'NOB', new_ops_area: 'CESEE' },
    { country: 'Lithuania', old_ops_area: 'NOB', new_ops_area: 'CESEE' },
    { country: 'Luxembourg', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Malta', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Moldova, Republic of', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Montenegro', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Netherlands', old_ops_area: 'CESE', new_ops_area: 'NOWE' },
    { country: 'Norway', old_ops_area: 'NOB', new_ops_area: 'NOWE' },
    { country: 'Poland', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Portugal', old_ops_area: 'UKIRWE', new_ops_area: 'NOWE' },
    { country: 'Romania', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Russia', old_ops_area: 'EERUT', new_ops_area: 'CESEE' },
    { country: 'Serbia', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    {
        country: 'Slovakia (Slovak Republic)',
        old_ops_area: 'CESE',
        new_ops_area: 'CESEE'
    },
    { country: 'Slovenia', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Spain', old_ops_area: 'UKIRWE', new_ops_area: 'NOWE' },
    { country: 'Sweden', old_ops_area: 'NOB', new_ops_area: 'NOWE' },
    { country: 'Switzerland', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Top Adjustment - CE', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'Top Adjustment - NO', old_ops_area: 'NOB', new_ops_area: 'NOWE' },
    { country: 'Top Adjustment - UK', old_ops_area: 'UKIRWE', new_ops_area: 'NOWE' },
    { country: 'Turkey', old_ops_area: 'EERUT', new_ops_area: 'CESEE' },
    { country: 'Ukraine', old_ops_area: 'CESE', new_ops_area: 'CESEE' },
    { country: 'United Kingdom', old_ops_area: 'UKIRWE', new_ops_area: 'NOWE' },
    { country: 'United States', old_ops_area: 'UKIRWE', new_ops_area: 'NOWE' },
    { country: 'Uzbekistan', old_ops_area: 'EERUT', new_ops_area: 'CESEE' }
];
